/* eslint-disable arrow-body-style */
import {Swappable} from '@shopify/draggable';
import AbstractView from '../../framework/view/abstract-view.js';

const createPuzleBoxTemplate = () => '<div class="puzzle__box"></div>`)';

export default class PuzleBoxView extends AbstractView {
  #boxHeight = null;
  #boxWidth = null;

  #cells = null;
  #items = null;

  #itemIdArray = null;
  #rowCount = null;
  #columnCount = null;

  #swappable = null;
  #itemMirrorWidth = null;
  #itemMirrorHeight = null;

  constructor() {
    super();
  }

  get template() {
    return createPuzleBoxTemplate();
  }

  #itemMoveHandler = () => {
    const mirror = this.element.querySelector('.draggable-mirror');
    mirror.style.width = this.#itemMirrorWidth;
    mirror.style.height = this.#itemMirrorHeight;
  };

  setItemDropHandler = (callback) => {
    this._callback.itemDrop = callback;
    this.#swappable.on('drag:stop', this.#itemDropHandler);
  };

  #itemDropHandler = () => {
    this._callback.itemDrop(this.#getCurrentIdArray());
  };

  resizeBox = (wrapperWidth, wrapperHeight, imgAsRatio) => {
    if (wrapperWidth / imgAsRatio > wrapperHeight) {
      this.#boxHeight = wrapperHeight;
      this.#boxWidth = this.#boxHeight * imgAsRatio;
    } else if (wrapperWidth / imgAsRatio < wrapperHeight) {
      this.#boxWidth = wrapperWidth;
      this.#boxHeight = wrapperWidth / imgAsRatio;
    } else {
      this.#boxWidth = wrapperWidth;
      this.#boxHeight = wrapperHeight;
    }

    this.element.style.width = `${this.#boxWidth}px`;
    this.element.style.height = `${this.#boxHeight}px`;

  };

  createCells = (rowCount, columnCount, imgSrc, itemIdArray) => {
    this.#itemIdArray = itemIdArray;
    this.#rowCount = rowCount;
    this.#columnCount = columnCount;

    this.element.style.gridTemplateRows = `repeat(${rowCount}, 1fr)`;
    this.element.style.gridTemplateColumns = `repeat(${columnCount}, 1fr)`;

    let index = 0;

    for (let row = 0; row < rowCount; row++) {
      for (let column = 0; column < columnCount; column++) {
        const cell = document.createElement('div');
        cell.classList.add('box__cell');

        const item = document.createElement('div');
        item.classList.add('box__item');
        item.id = itemIdArray[index];
        cell.append(item);
        this.element.append(cell);

        item.style.backgroundImage = `url(${imgSrc})`;
        item.style.backgroundRepeat = 'no-repeat';
        item.style.backgroundSize = `${this.element.clientWidth}px ${this.element.clientHeight}px`;
        item.style.backgroundPosition = `${-column * item.offsetWidth}px ${-row * item.offsetHeight}px`;

        this.#itemMirrorWidth = window.getComputedStyle(cell).width;
        this.#itemMirrorHeight = window.getComputedStyle(cell).height;

        index++;
      }
    }
    this.#cells = this.element.querySelectorAll('.box__cell');
    this.#items = this.element.querySelectorAll('.box__item');

    this.#swappable = new Swappable(document.querySelectorAll('.box__cell'), {
      draggable: '.box__item',
    });

    this.#swappable.on('drag:move', this.#itemMoveHandler);

  }; // \createCells

  shuffleItems = (shuffledIdArray) => {
    this.#items.forEach((item, index) => {
      const itemId = item.id;
      const newItemIndex = shuffledIdArray.indexOf(itemId);
      this.#cells[newItemIndex].appendChild(this.#items[index]);
    });
  };

  resizeCells = () => {
    for (let index = 0; index < this.#cells.length; index++) {
      const item = this.#cells[index].querySelector('.box__item');
      const itemIndex = this.#itemIdArray.indexOf(item.id);

      const col = itemIndex % this.#columnCount;
      const row = Math.floor(itemIndex / this.#columnCount);

      item.style.backgroundSize = `${this.element.clientWidth}px ${this.element.clientHeight}px`;
      item.style.backgroundPosition = `${-col * item.offsetWidth}px ${-row * item.offsetHeight}px`;
    }

    this.#itemMirrorWidth = window.getComputedStyle(this.#cells[0]).width;
    this.#itemMirrorHeight = window.getComputedStyle(this.#cells[0]).height;
  };

  #getCurrentIdArray() {
    const arr = [];

    for (const cell of this.#cells) {
      const item = cell.querySelector('.box__item');
      const itemId = item.id;
      arr.push(itemId);
    }

    return arr;
  }

}
