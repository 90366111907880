import Observable from '../framework/observable.js';
import {PieceCountParam} from '../consts';

export default class PieceCountModel extends Observable {
  #currentPieceCount = null;

  constructor() {
    super();
    this.#currentPieceCount = PieceCountParam['6_6'];
  }

  get pieceCount() {
    return this.#currentPieceCount;
  }

  setCurrentPieceCount = (pieceCount, updateType) => {
    this.#currentPieceCount = pieceCount;

    this._notify(updateType, this.#currentPieceCount);
  };
}
