const menuListData = {
  'Cars':
  [
    {
      'name': 'DeLorian',
      'filename':'Delorian',
      'format': 'jpg',
      'path': 'content/photos/Cars/mini',
    },
    {
      'name': 'Ford Mustang',
      'filename':'Ford_Mustang',
      'format': 'jpg',
      'path': 'content/photos/Cars/mini',
    },
    {
      'name': 'Lamborghini Diablo',
      'filename':'Lamborghini_Diablo',
      'format': 'jpg',
      'path': 'content/photos/Cars/mini',
    },
    {
      'name': 'Porsche 911',
      'filename':'Porsche_911',
      'format': 'jpg',
      'path': 'content/photos/Cars/mini'
    }
  ],

  'Cats':
  [
    {
      'name': 'Cat 1',
      'filename':'Cat1',
      'format': 'jpg',
      'path': 'content/photos/Cats/mini',
    },
    {
      'name': 'Cat 2',
      'filename':'Cat2',
      'format': 'jpg',
      'path': 'content/photos/Cats/mini',
    },
    {
      'name': 'Cat 3',
      'filename':'Cat3',
      'format': 'jpg',
      'path': 'content/photos/Cats/mini',
    },
    {
      'name': 'Cat 4',
      'filename':'Cat4',
      'format': 'jpg',
      'path': 'content/photos/Cats/mini'
    },
  ],

  'Dogs':
  [
    {
      'name': 'Dog 1',
      'filename':'Dog1',
      'format': 'jpg',
      'path': 'content/photos/Dogs/mini',
    },
    {
      'name': 'Dog 2',
      'filename':'Dog2',
      'format': 'jpg',
      'path': 'content/photos/Dogs/mini',
    },
    {
      'name': 'Dog 3',
      'filename':'Dog3',
      'format': 'jpg',
      'path': 'content/photos/Dogs/mini'
    },
  ],

  'Family':
  [
    {
      'name': 'Masquerade',
      'filename':'Masquerade',
      'format': 'jpg',
      'path': 'content/photos/Family/mini'
    },
  ],

  'Famous_people':
  [
    {
      'name': 'Albert Einstein',
      'filename':'Albert_einstein',
      'format': 'jpg',
      'path': 'content/photos/Famous_People/mini',
    },
    {
      'name': 'Michael Jordan',
      'filename':'Michael_Jordan',
      'format': 'jpg',
      'path': 'content/photos/Famous_People/mini'
    },
  ],

  'Games':
  [
    {
      'name': 'Cyberpunk 2077',
      'filename':'Cyberpunk_2077',
      'format': 'jpg',
      'path': 'content/photos/Games/mini',
    },
    {
      'name': 'Mafia 2',
      'filename':'Mafia_2',
      'format': 'jpg',
      'path': 'content/photos/Games/mini',
    },
    {
      'name': 'Skyrim',
      'filename':'Skyrim',
      'format': 'jpg',
      'path': 'content/photos/Games/mini',
    },
    {
      'name': 'Witcher 3',
      'filename':'Witcher_3',
      'format': 'jpg',
      'path': 'content/photos/Games/mini'
    },
  ],

  'IT':
  [
    {
      'name': 'IBM PC',
      'filename':'IBM_PC',
      'format': 'jpg',
      'path': 'content/photos/IT/mini',
    },
    {
      'name': 'Motherboard',
      'filename':'Motherboard',
      'format': 'png',
      'path': 'content/photos/IT/mini'
    },
  ],

  'Movies':
  [
    {
      'name': 'Gladiator',
      'filename':'Gladiator',
      'format': 'jpg',
      'path': 'content/photos/Movies/mini',
    },
    {
      'name': 'Pulp Fiction',
      'filename':'Pulp_Fiction',
      'format': 'jpg',
      'path': 'content/photos/Movies/mini',
    },
    {
      'name': 'Terminator 2',
      'filename':'Terminator_2',
      'format': 'jpg',
      'path': 'content/photos/Movies/mini'
    },
  ],
};

export {menuListData};
